<template>
    <div v-if="loading == false">
        <ChirpList
            :data_source="rows"
            :export_api="export_api"
            :totalCount="totalCount"
            :target_page="targetPage"
            @applyCriteria="modifyCriteria"
            :loadingColumns="loadingTable"
            :storeKey="storeKey"
            :column_meta="columnMeta"
            :useOverride="useOverride"
            @cancelOverride="handleCancelOverride"
        />
    </div>
    <div v-else>Loading...</div>
</template>

<script>
    import { tryGetFilter } from '@/util/tryGetFilter';

    const api_root = 'insurance/list_verifications';

    import ChirpList from '@/components/general/list/ChirpList';

    export default {
        name: 'InsuranceVerifications',
        components: { ChirpList },
        props: {
            overrideFilter: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                rows: [],
                targetPage: null, //'InsuranceVerificationDetail',
                loading: true,
                loadingTable: false,
                useOverride: false,
                columnMeta: [
                    { field: 'client', headerText: 'Client' },
                    { field: 'member_id', headerText: 'Member Id' },
                    { field: 'group_id', headerText: 'Group Id' },
                    { field: 'payer', headerText: 'Payer' },
                    { field: 'coverage_start_date', headerText: 'Coverage Start Date' },
                    { field: 'coverage_end_date', headerText: 'Coverage End Date' }
                ],
                criteriaProps: {},
                export_api: '/insurance/list_verifications',
                totalCount: 0
            };
        },
        computed: {
            criteria() {
                return this.useOverride && this.overrideFilter ? this.overrideCriteria : this.savedCriteria;
            },
            savedCriteria() {
                return tryGetFilter(this.$store, this.storeKey);
            },
            overrideCriteria() {
                return this.$store.getters['filters/overrideCriteria'](this.storeKey);
            },
            storeKey() {
                return this.pageMeta?.page || 'insurance-verifications';
            }
        },
        methods: {
            async load_data() {
                try {
                    const body = {
                        criteria: this.criteria
                    };
                    const res = await this.$api.post(this.export_api, body);

                    if (res.status >= 400) {
                        this.$toasted.error('Failed to fetch insurance verification roster.');
                        return;
                    }

                    this.rows = res.data.rows;

                    this.totalCount = res.data.total_count;
                } catch (err) {
                    this.$toasted.error('Failed to fetch insurance verification roster.');
                }
                this.$nextTick(() => {
                    this.loading = false;
                });
            },
            async init() {
                if (this.overrideFilter) {
                    this.useOverride = true;
                    this.$store.commit('filters/applyOverride', {
                        stateKey: this.storeKey,
                        criteria: this.overrideFilter
                    });
                }

                if (!this.criteria.sort || (this.criteria.sort && Object.keys(this.criteria.sort).length === 0)) {
                    this.$store.commit('filters/applyDefaultDateSort', {
                        stateKey: this.storeKey,
                        customDateKey: 'coverage_start_date',
                    });
                }

                await this.load_data();
            },
            async modifyCriteria(criteria) {
                if (this.targetPage) {
                    this.$store.commit('filters/update', {
                        criteriaPage: this.targetPage,
                        payload: criteria
                    });
                }
                this.criteriaProps = criteria;
                this.load_data();
            },
            handleCancelOverride() {
                this.useOverride = false;
            }
        },
        watch: {
            criteria: {
                deep: true,
                async handler() {
                    await this.load_data();
                }
            }
        },
        async created() {
            await this.init();
        }
    };
</script>
